.container {
  /* Size */
  --button-size: 50px;

  position: relative;
  width: 400px;
  height: 500px;
  margin: 1rem 0 2rem 0;
}

.slider-container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.item {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 600ms ease;
}

.img {
  width: 100%;
  height: 100%;
}

.button-left {
  position: absolute;
  width: var(--button-size);
  height: var(--button-size);
  top: 50%;
  border-radius: 50%;
  background-color: var(--color-bg-reversal-dark);
  z-index: 2;
  opacity: 0.5;
  transform: translate(-50%, -50%);
  transition: all 300ms ease;
}

.button-left:hover {
  opacity: 1;
}

.button-right {
  position: absolute;
  width: var(--button-size);
  height: var(--button-size);
  right: 0;
  top: 50%;
  border-radius: 50%;
  background-color: var(--color-bg-reversal-dark);
  z-index: 2;
  opacity: 0.5;
  transform: translate(50%, -50%);
  transition: all 300ms ease;
}

.button-right:hover {
  opacity: 1;
}

.icon {
  width: 100%;
  height: 100%;
  color: var(--color-bg-dark);
}

@media screen and (max-width: 768px) {
  .container {
    /* Size */
    --button-size: 40px;

    width: 300px;
    height: 375px;
  }
}

@media screen and (max-width: 640px) {
  .container {
    /* Size */
    --button-size: 30px;

    width: 250px;
    height: 312px;
  }
}
