.main-container {
  /* Size */
  --title-size: 1.5rem;
  --toggle-button-size: 3rem;
  /* Style */
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 0.5rem 2rem;
  background-color: transparent;
  z-index: 10;
  transition: all var(--animation-duration) ease-out;
}

.main-container.accent {
  background-color: var(--color-bg-transparent-dark);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid var(--color-grey);
}

.title-container {
  display: flex;
  align-items: center;
}

.title {
  margin: 0 1rem;
  color: var(--color-text);
  font-size: var(--title-size);
  font-weight: bold;
}

.title:hover {
  color: var(--color-accent);
}

.menu__container {
  display: flex;
}

.menus {
  display: flex;
  align-items: center;
  margin: 0;
}

.toggle-button-wrapper {
  position: relative;
  display: none;
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 1024px) {
  .main-container {
    flex-direction: column;
    padding: 1rem;
  }

  .left-side {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .title-container {
    width: 100%;
    flex: 1 0 0;
  }

  .menus {
    flex-direction: column;
    display: none;
  }

  .menus.open {
    display: block;
  }

  .menu {
    display: none;
    padding: 0.4rem 0;
  }

  .menu.open {
    display: block;
  }

  .toggle-button-wrapper {
    display: block;
  }

  .toggle-button {
    position: absolute;
    top: 0;
    left: 0;
    font-size: var(--toggle-button-size);
    color: var(--color-text);
    opacity: 0;
    transition: all var(--animation-duration) ease-out;
  }

  .toggle-button.show {
    opacity: 1;
  }

  .toggle-button:hover {
    filter: brightness(200%);
    transform: scale(1.1);
  }
}

@media screen and (max-width: 640px) {
  .title {
    margin-right: 0.5rem;
    font-size: 1.1rem;
  }

  .title:hover {
    color: var(--color-text);
  }

  .toggle-button:hover {
    filter: brightness(100%);
    transform: scale(1);
  }
}
