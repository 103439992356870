.container {
  /* Size */
  --title-size: 2rem;
  /* Styles */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem 1rem;
  color: var(--color-text);
}

.title-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 2rem 0;
}

.title {
  font-size: var(--title-size);
  font-weight: bold;
}

.divider-left,
.divider-right {
  display: inline-block;
  width: 23%;
  height: 1px;
  margin: auto;
  background-color: var(--color-text-transparent);
}

.divider-left {
  margin-right: 1rem;
}

.divider-right {
  margin-left: 1rem;
}

.worships {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .container {
    /* Size */
    --title-size: 1.2rem;
  }

  .divider-left,
  .divider-right {
    width: 15%;
  }
}
