.post-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 90vw;
  margin: 1rem auto;
}

.post-button-container {
  position: sticky;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background-color: var(--color-bg-reversal-transparent-dark);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.post-button {
  width: 200px;
  height: 50px;
  padding: 0;
  border: solid 1px var(--color-bg);
  font-size: 1.2rem;
  transition: all var(--animation-duration) ease-out;
}

.post-button:hover {
  background-color: var(--color-bg);
}

.post-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: var(--button-text-size);
  color: var(--color-text-reversal);
}

.post-link:hover {
  color: var(--color-text);
}
