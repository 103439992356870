.main-container {
  /* Size */
  --title-size: 1.2rem;
  --subtitle-size: 1.1rem;
  --divider-width: 200px;

  margin: 0 0.3rem;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 60px;
  text-transform: capitalize;
  color: var(--color-text);
  font-size: var(--title-size);
  font-weight: bold;
  opacity: 0.9;
}

.title.selected::after {
  content: '';
  width: 100px;
  margin-top: 0.5rem;
  border: 1px solid var(--color-accent);
}

.submenu-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  display: none;
}

.submenu-container.show {
  display: block;
}

.point {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: var(--color-accent);
  transform: translate(30px, -8px) rotate(45deg);
}

.submenus {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 200px;
  margin: 0;
  padding-left: 1.5rem;
  background-color: var(--color-accent);
}

.submenu-wrapper {
  height: 50px;
  padding: 0.8rem 0;
}

.submenu {
  font-size: var(--subtitle-size);
  color: var(--color-white);
  transition: all var(--animation-duration) ease-out;
}

.submenu:hover {
  color: var(--color-white);
  opacity: 0.5;
}

@media screen and (max-width: 1024px) {
  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .submenu-container {
    justify-content: center;
  }

  .point {
    display: none;
  }

  .submenus {
    position: relative;
    align-items: center;
    width: auto;
    padding-left: 0;
    background-color: transparent;
  }

  .submenus::before,
  .submenus::after {
    content: '';
    width: var(--divider-width);
    height: 1px;
    background-color: var(--color-text-transparent);
  }

  .submenu {
    color: var(--color-text);
  }

  .submenu:hover {
    color: var(--color-accent);
    opacity: 1;
  }
}
