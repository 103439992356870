.container {
  /* Size */
  --card-size: 200px;
  --title-size: 1rem;
  --text-size: 1.2rem;
  /* Styles */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: var(--card-size);
  height: var(--card-size);
  margin: 1rem;
  border-radius: 5px;
  background-color: var(--color-card-bg-dark);
  color: var(--color-text);
  font-weight: bold;
  overflow: hidden;
  box-shadow: var(--post-shadow);
  transition: all 600ms ease-in-out;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25%;
  background-color: var(--color-card-bg);
  font-size: var(--title-size);
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75%;
  gap: 0.5rem;
  font-size: var(--text-size);
}

@media screen and (max-width: 640px) {
  .container {
    /* Size */
    --card-size: 140px;
    --title-size: 0.8rem;
    --text-size: 1rem;
    /* Styles */
    margin: 0.5rem;
  }

  .point {
    width: 15px;
    height: 15px;
    transform: translateY(30px) rotate(45deg);
  }
}
