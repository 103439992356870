.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 1000px;
  margin: 0 auto;
}

.space-container {
  height: 80px;
}

.text-container {
  margin: 1rem 1rem 0 1rem;
  color: var(--color-text);
}

.image-container {
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.iframe {
  width: 600px;
  height: 336px;
  margin: 1rem 0;
  border-radius: 5px;
}

.information-container {
  margin: 0 2rem 2rem 0;
}

.writer {
  color: var(--color-text);
}

.date {
  margin: 0.5rem 0;
  color: #999999;
  font-size: 0.9rem;
}

.image {
  width: 90%;
  max-width: 600px;
  margin: 1rem;
  border-radius: 5px;
}

@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
  }
}

@media screen and (max-width: 640px) {
  .container {
    margin: 0;
  }

  .iframe {
    width: 100%;
    max-width: 500px;
    margin: 1rem 0;
    border-radius: 0;
  }

  .image {
    width: 100%;
    max-width: 500px;
    margin: 1rem 0;
    border-radius: 0;
  }
}
