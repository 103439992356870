.container {
  /* Size */
  --title-size: 4rem;
  --subtitle-size: 3rem;
  /* Styles */
  width: 100%;
  height: 50vh;
  background-color: var(--color-bg);
}

.filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  background-color: var(--color-bg-transparent);
  color: var(--color-text);
}

.title {
  font-size: var(--title-size);
}

.subtitle {
  font-size: var(--subtitle-size);
}

@media screen and (max-width: 768px) {
  .container {
    /* Size */
    --title-size: 2.5rem;
    --subtitle-size: 1.5rem;
    /* Styles */
    height: 30vh;
  }
}

@media screen and (max-width: 640px) {
  .container {
    /* Size */
    --title-size: 2rem;
    --subtitle-size: 1rem;
  }
}
