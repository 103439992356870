.container {
  /* Size */
  --text-size: 1rem;
  --title-size: 1.5rem;
  --icon-size: 100px;
  --icon-font-size: 4rem;
  /* Styles */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.thumbnails-wrapper {
  position: absolute;
  top: 0;
  width: 560px;
  height: 314px;
  border-radius: 1rem;
  overflow: hidden;
}

.thumbnails {
  width: 100%;
  height: 420px;

  transform: translateY(-53px);
}

.thumbnails:hover {
  transform: scale(1.1);
}

.thumbnails-filter {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(--color-accent-transparent);
  opacity: 0;
  transition: all var(--animation-duration) ease-out;
}

.thumbnails-filter:hover {
  cursor: pointer;
  opacity: 1;
}

.icon-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 100%;
  background-color: var(--color-white);
}

.icon {
  font-size: var(--icon-font-size);
  color: var(--color-accent-transparent);
}

.iframe {
  width: 560px;
  height: 315px;
  opacity: 0;
}

.iframe.show {
  opacity: 1;
}

.date {
  margin: 0.5rem 0;
  font-size: var(--text-size);
  color: var(--color-text);
}

.title {
  color: var(--color-text);
  font-size: var(--title-size);
  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .container {
    /* Size */
    --title-size: 1.2rem;
    --icon-size: 60px;
    --icon-font-size: 2rem;
  }

  .thumbnails-wrapper {
    width: 320px;
    height: 180px;
  }

  .thumbnails {
    height: 240px;
    transform: translateY(-30px);
  }

  .iframe {
    width: 320px;
    height: 180px;
  }
}
