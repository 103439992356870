.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  margin-top: 1rem;
  font-size: var(--title-size);
  color: var(--color-text);
}

.divider {
  width: 100px;
  height: var(--divider-height);
  margin: 1rem 0;
  background-color: var(--color-text);
}

.verse {
  padding: 0 1rem;
  text-align: center;
  font-size: var(--verse-size);
  color: var(--color-text);
}
