.container {
  width: 400px;
  border-radius: 10px;
  margin: 1rem;
  background: var(--color-card-bg-dark);
  box-shadow: var(--post-shadow);
  overflow: hidden;
  cursor: pointer;
  transition: all 200ms ease-in;
}

.container:hover {
  transform: scale(1.05);
}

.preview-image {
  width: 100%;
}

.text-container {
  width: 100%;
  padding: 1rem 2rem;
}

.date {
  margin: 0;
  color: #999999;
  font-size: 0.9rem;
}

.title {
  margin: 0 0 1rem 0;
  font-size: 2rem;
  color: var(--color-text);
}

@media screen and (max-width: 1024px) {
  .container {
    width: 350px;
  }

  .title {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 400px;
  }

  .title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 640px) {
  .container {
    width: 90vw;
    margin: 1rem 0;
  }

  .text-container {
    padding: 1rem 1.2rem;
  }

  .title {
    margin: 0 0 0.5rem 0;
    font-size: 1.5rem;
  }
}
