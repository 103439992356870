.background {
  background: url('./img/home_background.JPG') center/cover no-repeat;
  height: 100vh;
}

.background__filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-transparent);
  color: var(--color-text);
}

.title {
  margin: 2rem;
  font-size: 5rem;
  transition: all 1000ms ease;
}

.subtitle {
  font-size: 2rem;
  transition: all 1000ms ease;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 4rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 640px) {
  .title {
    margin: 1rem;
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }
}
