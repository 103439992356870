.container {
  /* Size */
  --text-size: 1.5rem;
  --divider-width: 150px;
  /* Styles */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 700px;
  margin: 1rem;
}

.map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.divider {
  width: var(--divider-width);
  height: 1px;
  margin: 1rem 0 0.5rem 0;
  background-color: var(--color-text-transparent);
}

.button {
  width: 330px;
  height: 100px;
  border-radius: 15px;
  margin-bottom: 1rem;
  background-color: var(--color-accent);
  transition: all var(--animation-duration) ease-out;
}

.button:hover {
  transform: scale(1.1);
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: var(--text-size);
  color: var(--color-text-reversal);
}

.link:hover {
  color: var(--color-text-reversal);
}

.subtitle {
  color: var(--color-text);
  font-size: var(--text-size);
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .container {
    /* Size */
    --text-size: 1rem;
    --divider-width: 100px;
  }

  .icon-cards {
    max-width: 500px;
  }

  .button {
    width: 180px;
    height: 58px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 640px) {
  .button:hover {
    transform: scale(1);
  }
}
