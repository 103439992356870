.container {
  /* Size */
  --title-size: 3.5rem;
  --verse-size: 1rem;
  --button-text-size: 2rem;
  --divider-height: 5px;
}

.padding {
  width: 100%;
  height: 1rem;
}

.videos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.youtube-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  background-color: var(--color-bg-reversal);
}

.youtube-button {
  width: 330px;
  height: 70px;
  padding: 0;
  border: solid 1px var(--color-bg);
  transition: all var(--animation-duration) ease-out;
}

.youtube-button:hover {
  background-color: var(--color-bg);
}

.youtube-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: var(--button-text-size);
  color: var(--color-text-reversal);
}

.youtube-link:hover {
  color: var(--color-text);
}

@media screen and (max-width: 768px) {
  .container {
    /* Size */
    --title-size: 1.3rem;
    --verse-size: 0.9rem;
    --button-text-size: 1rem;
    --divider-height: 2px;
  }

  .youtube-container {
    height: 80px;
  }

  .youtube-button {
    width: 200px;
    height: 50px;
  }
}
