.introduction {
  display: flex;
  max-width: 1200px;
  width: 80vw;
  margin: 5rem auto;
  gap: 2rem;
  align-items: center;
  color: var(--color-text);
  transition: all 600ms ease;
}

.img {
  width: 40vw;
  max-width: 500px;
  margin: 1rem 5rem 1rem 1rem;
  padding-bottom: 74.96% 0;
  border-radius: 1rem;
  margin: 0;
  transition: all 600ms ease;
}

.text-container {
  margin: 3rem 0;
  font-size: 1rem;
}

.text-emphasize {
  font-size: 1.9rem;
}

@media screen and (max-width: 1024px) {
  .img {
    width: 60vw;
    border-radius: 0.8rem;
  }

  .introduction {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .text-container {
    margin: 2rem 0;
    font-size: 0.8rem;
  }

  .text-emphasize {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 640px) {
  .img {
    width: 80vw;
    border-radius: 0.5rem;
  }

  .text-container {
    margin: 1rem 0;
    font-size: 0.7rem;
  }

  .text-emphasize {
    font-size: 1.3rem;
  }
}
