.background {
  display: flex;
  align-items: center;
  width: 60px;
  height: 36px;
  border-radius: 18px;
  background-color: var(--color-dark-mode-bg);
}

.button-wrapper {
  width: 30px;
  height: 30px;
  background-color: var(--color-white);
  border-radius: 100%;
  transition: all 150ms ease-out;
  transform: translateX(10%);
}

.button-wrapper.selected {
  transform: translateX(90%);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0;
  color: var(--color-dark-mode-icon);
  transition: all 150ms ease-out;
}

.button:hover {
  filter: brightness(125%);
  transform: scale(1.1);
}

.icon {
  font-size: 1rem;
}

@media screen and (max-width: 640px) {
  .background {
    width: 32px;
    height: 32px;
  }

  .button-wrapper {
    width: 26px;
    height: 26px;
    transform: translateX(11%);
  }

  .button-wrapper.selected {
    transform: translateX(11%);
  }

  .button:hover {
    filter: brightness(100%);
    transform: scale(1);
  }
}
