.container {
  /* Size */
  --icon-size: 3rem;
  --title-size: 2rem;
  --text-size: 1.2rem;
  /* Styles */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 210px;
  margin: 1rem 2rem;
  color: var(--color-text);
  transition: all 600ms ease-in-out;
}

.container:hover {
  transform: scale(1.2);
}

.icon {
  font-size: var(--icon-size);
}

.title {
  font-size: var(--title-size);
}

.divider {
  width: 50px;
  height: 1px;
  margin: 0.5rem 0;
  background-color: var(--color-text-transparent);
}

.infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--text-size);
}

@media screen and (max-width: 768px) {
  .container {
    /* Size */
    --icon-size: 2rem;
    --title-size: 1.5rem;
    --text-size: 0.9rem;
    /* Styles */
    height: 160px;
    margin: 0.5rem 1rem;
  }
}
