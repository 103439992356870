.container {
  /* Size */
  --img-max-width: 1000px;
  --img-width: 80vw;
  --text-size: 1.5rem;
  --button-icon-size: 2rem;
  --button-size: 50px;

  /* Styles */
  display: inline-block;
  position: relative;
  margin: 1rem;
}

.image {
  max-width: var(--img-max-width);
  width: var(--img-width);
}

.image-filter {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: var(--img-max-width);
  width: var(--img-width);
  height: 100%;
  top: 0;
  padding: 0 1rem;
  background-color: #242424aa;
  color: var(--color-white);
  font-size: var(--text-size);
  opacity: 0;
  transition: all 500ms ease-out;
}

.image-filter.show {
  opacity: 1;
}

.buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 1rem;
  right: 0.5rem;
  border-radius: 2px;
  background-color: var(--color-white);
}

.button {
  width: var(--button-size);
  height: var(--button-size);
  padding: 0;
  font-size: var(--button-icon-size);
  color: #242424aa;
}

.button:hover {
  color: #242424;
}

.divider {
  height: 1px;
  margin: 0 5px;
  background-color: #24242455;
}

@media screen and (max-width: 640px) {
  .container {
    /* Size */
    --text-size: 1rem;
    --button-icon-size: 1.2rem;
    --button-size: 30px;
    --img-width: 100vw;
  }

  .button:hover {
    color: #242424aa;
  }
}
