:root {
  /* Color */
  --color-bg-dark: #e9d9c7;
  --color-bg: #f1e7dc;
  --color-bg-transparent-dark: #e9d9c799;
  --color-bg-transparent: #f1e7dc99;
  --color-bg-reversal-dark: #171717;
  --color-bg-reversal: #242424;
  --color-bg-reversal-transparent-dark: #17171799;
  --color-grey: #d1d1d1;
  --color-text: #1a202b;
  --color-text-transparent: #22243baa;
  --color-text-reversal: #fdfffd;
  --color-text-link: #4f636f;
  --color-white: white;
  --color-accent: #fdba74;
  --color-accent-transparent: #fdba74aa;
  --color-dark-mode-bg: #242424;
  --color-dark-mode-icon: #242424;
  --color-card-bg-dark: #f5f5f5;
  --color-card-bg: #ffffff;

  /* Time */
  --animation-duration: 150ms;

  /* Size */
  --size-sm: 640px;
  --size-md: 768px;
  --size-lg: 1024px;
  --size-xl: 1280px;
  --size-xxl: 1536px;

  /* Font Size */
  --font-size-xxl: 2.5rem;
  --font-size-xl: 2rem;
  --font-size-lg: 1.5rem;
  --font-size-md: 1rem;
  --font-size-sm: 0.8rem;

  /* shadow */
  --post-button-shadow: 1px 10px 2rem rgba(255, 94, 98, 0.5);
  --post-shadow: 1px 1px 2rem rgba(0, 0, 0, 0.3);
}

html.dark {
  /* Color */
  --color-bg-dark: #171717;
  --color-bg: #242424;
  --color-bg-transparent-dark: #17171799;
  --color-bg-transparent: #24242466;
  --color-bg-reversal-dark: #f5f5f5;
  --color-bg-reversal: #fdfdfd;
  --color-bg-reversal-transparent-dark: #fffdf699;
  --color-grey: #4e4e4e;
  --color-text: #fdfffd;
  --color-text-transparent: #fdfffdaa;
  --color-text-reversal: #22243b;
  --color-text-link: #96a8b3;
  --color-accent: #e1b283;
  --color-dark-mode-bg: #fdba74;
  --color-dark-mode-icon: #fdba74;
  --color-card-bg-dark: #212121;
  --color-card-bg: #3f3f3f;

  /* shadow */
  --post-button-shadow: 1px 10px 2rem rgba(195, 34, 38, 0.5);
  --post-shadow: 1px 1px 2rem rgba(120, 120, 120, 0.3);
}

@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=Work+Sans&display=swap');

body {
  margin: 0;
  font-family: 'Nanum Gothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding-left: 0;
}

button {
  outline: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

a {
  text-decoration: none;
  color: var(--color-text-link);
  font-weight: bold;
  transition: all 150ms ease-out;
}

a:hover {
  color: var(--color-accent);
}

@media screen and (max-width: 768px) {
  :root {
    /* Font Size */
    --font-size-xxl: 2rem;
    --font-size-xl: 1.7rem;
    --font-size-lg: 1.3rem;
    --font-size-md: 0.8rem;
    --font-size-sm: 0.6rem;
  }
}

@media screen and (max-width: 640px) {
  :root {
    /* Font Size */
    --font-size-xxl: 1.8rem;
    --font-size-xl: 1.3rem;
    --font-size-lg: 1rem;
    --font-size-md: 0.6rem;
    --font-size-sm: 0.4rem;
  }
}
