.container {
  /* Size */
  --title-size: 2.5rem;
  --subtitle-size: 1.5rem;
  --text-size: 1.2rem;
}

.introduction-container {
  display: flex;
  justify-content: center;
  margin: 2rem 4rem;
  border-bottom: 1px solid var(--color-grey);
}

.avatar {
  width: 40vw;
  max-width: 500px;
  margin: 1rem 5rem 1rem 1rem;
  padding-bottom: 74.96% 0;
  border-radius: 1rem;
  transition: all 600ms ease;
}

.introduction {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  color: var(--color-text);
  transition: all 600ms ease;
}

.title {
  font-size: var(--title-size);
  font-weight: bold;
}

.subtitle {
  margin: 0.5rem 0;
  font-size: var(--subtitle-size);
}

.text {
  margin: 0.5rem 0;
  font-size: var(--text-size);
}

.history {
  display: flex;
  flex-direction: column;
  max-width: 80vw;
  margin: auto;
  padding: 0 6rem;
  color: var(--color-text);
}

@media screen and (max-width: 1024px) {
  .container {
    /* Size */
    --title-size: 2rem;
    --subtitle-size: 1.5rem;
    --text-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .introduction-container {
    flex-direction: column;
    margin: 1rem;
  }

  .avatar-wrapper {
    text-align: center;
  }

  .avatar {
    width: 80vw;
    margin: 0;
  }

  .history {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 640px) {
  .history {
    max-width: 95vw;
  }
}
