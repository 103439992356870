.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: #242424dd;
  transition: opacity 300ms ease;
  z-index: 1;
}

.notice-img {
  display: block;
  margin: 0 auto;
  width: 100vw;
  max-width: 768px;
}

.date-background {
  height: 0;
}

.date {
  display: inline-block;
  color: #ffffff;
  background-color: #1d315e;
  padding: 10px 20px;
  margin: 0;
  border-radius: 25px;
  font-weight: bold;
  font-size: 1.5rem;
  transform: translateY(-50%);
}

.contents {
  width: 100vw;
  max-width: 768px;
  margin: 0 auto;
  padding: 50px 0;
  background-color: #83befb;
  color: #1d315e;
  font-weight: bold;
  font-size: 1.5rem;
  border-top: solid black;
  border-bottom: solid black;
}

.foot {
  width: 100vw;
  height: 40px;
  max-width: 768px;
  margin: 0 auto;
  background-color: #ffffff;
}

@media screen and (max-width: 640px) {
  .date {
    font-size: 1rem;
  }

  .contents {
    font-size: 1rem;
    padding: 40px 0;
  }

  .foot {
    height: 20px;
  }
}

@media screen and (max-width: 640px) {
  .date {
    padding: 10px 15px;
    font-size: 0.6rem;
  }

  .contents {
    font-size: 0.7rem;
    padding: 30px 0;
  }

  .foot {
    height: 20px;
  }
}
