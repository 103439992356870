.container {
  /* Size */
  --title-size: 2rem;
  --text-size: 1.2rem;
}

.introduction {
  max-width: 800px;
  width: 80vw;
  margin: 4rem auto;
  gap: 1rem;
  text-align: center;
  align-items: center;
  color: var(--color-text);
  transition: all 600ms ease;
}

.divider {
  width: 150px;
  height: 1px;
  margin: 1rem auto;
  background-color: var(--color-text);
}

.title {
  font-size: var(--title-size);
}

.text {
  font-size: var(--text-size);
}

@media screen and (max-width: 640px) {
  .container {
    /* Size */
    --text-size: 1rem;
  }
}
