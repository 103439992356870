.container {
  /* Size */
  --title-size: 2rem;
  --title-emphasize-size: 3rem;
  --text-size: 1.2rem;
}

.introduction {
  max-width: 1100px;
  width: 80vw;
  margin: 4rem auto;
  gap: 1rem;
  text-align: center;
  align-items: center;
  color: var(--color-text);
  transition: all 600ms ease;
}

.introduction ul {
  margin: 0;
}

.divider {
  width: 150px;
  height: 1px;
  margin: 3rem auto;
  background-color: var(--color-text);
}

.title {
  font-size: 2rem;
}

.title-emphasize {
  font-size: 2.8rem;
}

.text-container {
  margin: 3rem 0;
}

.subtitle {
  font-size: 1.7rem;
}

.subtitle-emphasize {
  font-size: 1.9rem;
}

.verse {
  margin: 0.5rem 0;
  font-weight: bold;
  opacity: 0.8;
}

@media screen and (max-width: 1024px) {
  .introduction {
    width: 90vw;
    margin: 3rem auto;
  }

  .title {
    font-size: 1.5rem;
  }

  .title-emphasize {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.4rem;
  }

  .subtitle-emphasize {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 768px) {
  .introduction {
    width: 90vw;
  }

  .text-container {
    margin: 2rem 0;
  }

  .title {
    font-size: 1.2rem;
  }

  .title-emphasize {
    font-size: 1.8rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .subtitle-emphasize {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 640px) {
  .divider {
    margin: 2rem auto 0 auto;
  }

  .title {
    font-size: 1.1rem;
  }

  .title-emphasize {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }
}
